
import { Component, Vue, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import { mapProcessingFields } from "@/store/modules/processing";
import { mapAllflexFields } from "@/store/modules/allflex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import { initializeChart, drawChart } from "../scale-graph";
import * as sgtinH from "@/helpers/sgtin";
import { gatesSummary } from "@/helpers/gates-summary";
import { checkTagAgainstAnimalsInBatch, checkTagAgainstAnimalsInDatabase } from "@/helpers/program-tag";

@Component({
  components: {
    DimssaButton,
    Keypress: () => import("vue-keypress"),
  },
  computed: {
    ...mapProcessingFields([
      "currentBatchSetup.processingResult",
      "currentBatchSetup.batchDetails",
      "currentAnimal.brokenNeedle",
      "currentAnimal.notes",
      //"currentAnimal.isNonStandard"
    ]),
    ...mapAllflexFields(["allflex"]),
  },
})
export default class ProcessAnimal extends Vue {
  // get currentAnimal() {
  //   return this.$store.getters["processing/getField"]("currentAnimal");
  // }

  retagAnimal() {
    let processingResult: Models.ProcessingResult = this.$store.getters["processing/getField"]("currentBatchSetup.processingResult") as Models.ProcessingResult;
    if (processingResult.processingFunction === "new" && this.allowedAnimals.length === 0) throw Error("retagAnimal() was somehow called from a new batch");
    this.$router.push({ name: "retag", params: { mode: "processing" } });
  }

  visual(sgtin: string) {
    return sgtinH.visual(sgtin);
  }

  scaleActivityToggle: boolean = false;

  get scaleActivityColour() {
    return this.scaleActivityToggle ? "lime" : "white";
  }

  isAnimalSick: boolean = false;

  gender: string = "";
  breed: string = "";
  numTeeth: string = ""; //casted to number when saved to currentAnimal
  isNonStandard: boolean = false;

  averageAdg: number | "" = "";
  sgtinToAdgs: { [sgtin: string]: { adg: number | "N/A"; adgTotal: number | "N/A" } } = {};

  get numProcessedAnimals() {
    return this.$store.state.processing.numProcessedAnimals;
  }

  calcAdg(currentMass: number, currentTime: number, animal: Models.Animal) {
    const events = animal?.events.filter((e) => e.type.startsWith("processing_")) ?? [];

    if (events.length === 0) return { adgLatest: undefined, adgTotal: undefined, daysLatest: undefined, daysTotal: undefined };

    let newestEvent = events[0];
    let oldestEvent = events[events.length - 1];

    const getAdgAndDays = (e: Models.AnimalEvent) => {
      const massDiff = (currentMass as number) - (e.mass as number);
      let daysBetween: number = this.moment(currentTime).diff(this.moment(e.time), "days");
      if (daysBetween !== 0) return { adg: parseFloat((massDiff / daysBetween).toFixed(1)), days: daysBetween };
      else return { adg: undefined, days: undefined };
    };

    const { adg: adgLatest, days: daysLatest } = getAdgAndDays(newestEvent);
    const { adg: adgTotal, days: daysTotal } = getAdgAndDays(oldestEvent);

    return { adgLatest, adgTotal, daysLatest, daysTotal };
  }

  async calcAdgs() {
    let processedAnimals = await this.$store.getters["processing/currentProcessedAnimals"]();
    console.log("calcAdgs, " + processedAnimals.length);
    for (const pa of processedAnimals) {
      if (!this.sgtinToAdgs[pa.sgtin] || this.sgtinToAdgs[pa.sgtin].adg === "N/A" || this.sgtinToAdgs[pa.sgtin].adgTotal === "N/A") {
        let animal: Models.Animal = await this.$store.dispatch("dataManager/getAnimal", pa.sgtin);
        const { adgLatest, adgTotal, daysLatest, daysTotal } = this.calcAdg(pa.mass, pa.time, animal);
        this.sgtinToAdgs[pa.sgtin] = { adg: adgLatest ?? "N/A", adgTotal: adgTotal ?? "N/A" };
      } else {
        console.log(`${pa.sgtin}: ${JSON.stringify(this.sgtinToAdgs[pa.sgtin])}`);
      }
    }

    let total = 0;
    let num = 0;
    for (const adgs of Object.values(this.sgtinToAdgs)) {
      if (adgs.adg === "N/A" || isNaN(adgs.adg)) {
        this.averageAdg = "";
        total = 0;
        num = 0;
        break;
      }
      total += adgs.adg;
      num++;
    }
    if (num > 0) {
      this.averageAdg = parseFloat((total / num).toFixed(1));
    }
  }

  adgLatest: number | "" = ""; //ADG between captured mass and newest event
  adgTotal: number | "" = ""; //ADG between captured mass and oldest event
  daysLatest: number | "" = ""; //Days between today and newest event
  daysTotal: number | "" = ""; //Days between today and oldest event

  async updateAdgUi(sgtin: string | undefined, mass: number | "") {
    if (!sgtin || mass === "") {
      this.adgLatest = "";
      this.adgTotal = "";
      this.daysLatest = "";
      this.daysTotal = "";
      return;
    }

    let animal: Models.Animal = await this.$store.dispatch("dataManager/getAnimal", sgtin);
    const { adgLatest, adgTotal, daysLatest, daysTotal } = this.calcAdg(mass, Date.now(), animal);

    this.adgLatest = adgLatest ?? "";
    this.adgTotal = adgTotal ?? "";
    this.daysLatest = daysLatest ?? "";
    this.daysTotal = daysTotal ?? "";
  }

  gatesSummary: any[] = [];
  async calcGatesSummary() {
    this.gatesSummary = await gatesSummary(this.$store.state.processing.currentBatchSetup);
  }

  onChangeIsAnimalSick() {
    console.log("isAnimalSick: ", this.isAnimalSick);
    this.$store.commit("processing/updateField", { path: "isAnimalSick", value: this.isAnimalSick });
    this.determineSortingGate();
  }

  onKeyEscape() {
    if (this.hasFocusGenderSelect && this.$refs["gender-select"]) (this.$refs["gender-select"] as any).blur();
    if (this.hasFocusBreedSelect && this.$refs["breed-select"]) (this.$refs["breed-select"] as any).blur();
    if (this.hasFocusNumTeethSelect && this.$refs["num-teeth-select"]) (this.$refs["num-teeth-select"] as any).blur();
    if (this.hasFocusVisualTag && this.$refs["visual-tag-input"]) (this.$refs["visual-tag-input"] as any).blur();
    if (this.hasFocusAnimalNotes && this.$refs["animal-notes"]) (this.$refs["animal-notes"] as any).blur();
  }

  onKeyEnter() {
    if (this.hasFocusGenderSelect && this.$refs["gender-select"]) (this.$refs["gender-select"] as any).blur();
    if (this.hasFocusBreedSelect && this.$refs["breed-select"]) (this.$refs["breed-select"] as any).blur();
    if (this.hasFocusNumTeethSelect && this.$refs["num-teeth-select"]) (this.$refs["num-teeth-select"] as any).blur();
    if (this.hasFocusVisualTag && this.$refs["visual-tag-input"]) (this.$refs["visual-tag-input"] as any).blur();
    if (this.hasFocusAnimalNotes && this.$refs["animal-notes"]) (this.$refs["animal-notes"] as any).blur();
  }

  hasFocusGenderSelect: boolean = false;
  hasFocusBreedSelect: boolean = false;
  hasFocusNumTeethSelect: boolean = false;
  hasFocusVisualTag: boolean = false;
  hasFocusAnimalNotes: boolean = false;

  isInputInFocusOrZeroScalePopupDisplayed() {
    return (
      this.hasFocusGenderSelect || this.hasFocusBreedSelect || this.hasFocusVisualTag || this.hasFocusAnimalNotes || this.$store.state.scale.zeroScalePopup
    );
  }

  onKeyPressCaptureMass() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    this.onClickCaptureMass();
  }

  onKeyPressProgramUhfTag() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    if (this.$store.state.processing.currentBatchSetup.processingResult.processingFunction === "new" && this.allowedAnimals.length === 0) this.programTag();
  }

  onKeyPressConfirmTag() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    this.confirmTag();
  }

  onKeyPressNextAnimal() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    this.onClickFinishAnimal();
  }

  currentMass: number | "" = "";

  get colClass() {
    return this.$store.state.lightDarkMode === "light" ? "grey lighten-1" : "grey darken-4";
  }

  selectGender() {
    if (this.processingResult.processingFunction !== "new" || this.allowedAnimals.length > 0) return;
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    (this.$refs["gender-select"] as any).focus();
    (this.$refs["gender-select"] as any).activateMenu();
  }

  selectBreed() {
    if (this.processingResult.processingFunction !== "new" || this.allowedAnimals.length > 0) return;
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    (this.$refs["breed-select"] as any).focus();
    (this.$refs["breed-select"] as any).activateMenu();
  }

  selectNumTeeth() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    (this.$refs["num-teeth-select"] as any).focus();
    (this.$refs["num-teeth-select"] as any).activateMenu();
  }

  enterVisualNumber() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    (this.$refs["visual-tag-input"] as any).focus();
  }

  genderChange(gender: any) {
    this.gender = gender;
    this.$store.commit("processing/updateField", { path: "currentAnimal.gender", value: gender === "" ? undefined : gender });

    this.determineSortingGate();
    console.log(this.sortingGate);
    this.hasFocusGenderSelect = false;
    if (this.$refs["gender-select"]) (this.$refs["gender-select"] as any).blur();
  }

  breedChange(breed: any) {
    this.breed = breed;
    this.$store.commit("processing/updateField", { path: "currentAnimal.breed", value: breed === "" ? undefined : breed });
    this.hasFocusBreedSelect = false;
    if (this.$refs["breed-select"]) (this.$refs["breed-select"] as any).blur();
  }

  numTeethChange(numTeeth: any) {
    this.numTeeth = numTeeth;
    this.$store.commit("processing/updateField", { path: "currentAnimal.numTeeth", value: numTeeth === "" ? undefined : parseInt(numTeeth) });
    this.hasFocusNumTeethSelect = false;
    if (this.$refs["num-teeth-select"]) (this.$refs["num-teeth-select"] as any).blur();
  }

  get moment() {
    return this.$store.state.moment;
  }

  processedAnimalHeaders = [
    { text: "#", value: "numberInQueue", sortable: false, align: "center" },
    { text: "Tag ID", value: "sgtin", sortable: false, align: "center" },
    { text: "Owner", value: "owner", sortable: false, align: "center" },
    { text: "Breed", value: "breed", sortable: false, align: "center" },
    { text: "Gender", value: "gender", sortable: false, align: "center" },
    { text: "Mass", value: "mass", sortable: false, align: "center" },
    { text: "ADG", value: "adg", sortable: false, align: "center" },
    { text: "Total ADG", value: "adgTotal", sortable: false, align: "center" },
    { text: "Gate", value: "gate", sortable: false, align: "center" },
    { text: "Destination", value: "destination", sortable: false, align: "center" },
    { text: "Kraal/Camp", value: "kraalId", sortable: false, align: "center" },
    { text: "Sick", value: "isSick", sortable: false, align: "center" },
    { text: "Vaccination", value: "vaccinationDescription", sortable: false, align: "center" },
    { text: "Time", value: "time", sortable: false, align: "center" },
  ];

  customAnimalTableSort(items: Models.ProcessedAnimal[], index: number, isDesc: boolean) {
    //forced descending sort on time (newest entries shown at the top of the table)
    return items.sort((a: Models.ProcessedAnimal, b: Models.ProcessedAnimal) => (a.time > b.time ? -1 : a > b ? 1 : 0));
  }

  processedAnimals: any[] = []; //processed animals with extra data like adg and adgTotal
  async calcProcessedAnimals() {
    let processingResult: Models.ProcessingResult = this.$store.getters["processing/getField"]("currentBatchSetup.processingResult") as Models.ProcessingResult;
    //this.processedAnimals = this.$store.state.processing.currentBatchSetup.processingResult.processedAnimals.map(
    const t = await this.$store.getters["processing/currentProcessedAnimals"]();
    this.processedAnimals = t.map((pa: Models.ProcessedAnimal, index: number) => {
      let ret: any = lodash.cloneDeep(pa);
      ret.numberInQueue = index + 1;
      ret.adg = processingResult.processingFunction === "new" || !pa.sgtin ? "N/A" : this.sgtinToAdgs[pa.sgtin]?.adg ?? "N/A";
      ret.adgTotal = processingResult.processingFunction === "new" || !pa.sgtin ? "N/A" : this.sgtinToAdgs[pa.sgtin]?.adgTotal ?? "N/A";
      ret.destination = pa.sorting && pa.sorting.destination ? pa.sorting.destination.type : "";
      ret.kraalId = pa.sorting && pa.sorting.destination ? pa.sorting.destination.kraalId : "";
      ret.gate = pa.sorting && pa.sorting.gate ? pa.sorting.gate.description : "";
      ret.vaccinationDescription = ret.vaccinations[0].vaccination ? ret.vaccinations[0].vaccination.vaccination.description : "";
      ret.isSick = pa.vaccinations.find((vacDesc) => vacDesc.description === "sick") ? "Y" : "";
      ret.gender = pa.gender ? pa.gender[0].toUpperCase() + pa.gender.slice(1).toLowerCase() : "";
      let owner = this.$store.state.processing.currentBatchSetup.batchDetails.customFeeder;
      ret.owner = `${owner.name} ${owner.surname}`;
      return ret;
    });
  }

  async created() {
    this.$store.commit("log/recent", "");

    if (!this.$store.getters["processing/getField"]("currentAnimal")) {
      let pa = new Models.ProcessedAnimal();
      pa.notes = "";
      pa.isNonStandard = false;
      this.isNonStandard = false;
      this.$store.commit("processing/updateField", { path: "currentAnimal", value: pa });
    }

    //if animal has not been retagged then clear it's sgtin (force them to redo it since app was close and opened again)
    //if animal has been retagged then keep sgtin as this sgtin is the newly programmed tag and the user will come in from a different view (from Retag view)
    if (!this.hasRetag()) this.$store.commit("processing/updateField", { path: "currentAnimal.sgtin", value: "" });
    else {
      let retag = this.$store.state.processing.currentAnimal.retag as Models.Retag;
      this.$store.commit("processing/updateField", { path: "currentAnimal.gender", value: retag.gender ? retag.gender : "" });
      this.$store.commit("processing/updateField", { path: "currentAnimal.breed", value: retag.breed ? retag.breed : "" });
    }

    if (this.$store.getters["processing/getField"]("isAnimalSick") === undefined) {
      this.$store.commit("processing/updateField", { path: "isAnimalSick", value: false });
    }
    this.isAnimalSick = this.$store.getters["processing/getField"]("isAnimalSick");

    let currentBatchSetup = this.$store.state.processing.currentBatchSetup;
    if ((currentBatchSetup.processingFunction !== "new" || this.allowedAnimals.length > 0) && !this.hasRetag()) {
      //don't clear out gender and breed if retagged, gender and breed will be filled out from retag
      //clear if not "new" processing because tag needs to be scanned again and then gender and breed will be determined from that
      this.$store.commit("processing/updateField", { path: "currentAnimal.gender", value: "" });
      this.$store.commit("processing/updateField", { path: "currentAnimal.breed", value: "" });
      //this.$store.commit("processing/updateField", { path: "currentBatchSetup.batchDetails.customFeeder", value: { name: "", surname: "" } });
    }

    this.gender = this.$store.getters["processing/getField"]("currentAnimal.gender");
    this.breed = this.$store.getters["processing/getField"]("currentAnimal.breed");
    this.numTeeth = this.$store.getters["processing/getField"]("currentAnimal.numTeeth") ?? "";

    this.determineSortingGate();

    let i = lodash.findIndex(this.$store.state.processing.currentAnimal.otherTags, (o: Models.ProcessedAnimalTag) => o.type === "visual");
    if (i < 0) this.visualTag = "";
    else this.visualTag = this.$store.state.processing.currentAnimal.otherTags[i].value;

    await this.calcAdgs();
    this.calcProcessedAnimals();
    await this.calcGatesSummary();
    this.determineIsBatchFinishedByAnimalQuantity();

    //if batch is new intake and not finished then check to autofill gender and breed
    this.checkAutofillGenderBreed();
  }

  firstMassCaptured: boolean = false;

  delta: number = 250; //ms

  zeroScaleTimer() {
    this.$store.commit("scale/zeroScaleCurrentTime", this.$store.state.scale.zeroScaleCurrentTime + this.delta);

    if (!this.$store.state.scale.connected || this.currentMass === "" || Math.abs(this.currentMass) > 2) this.$store.commit("scale/zeroScaleCurrentTime", 0);

    if (this.$store.state.scale.zeroScaleCurrentTime > this.$store.state.scale.zeroScaleMaxTime) {
      this.$store.commit("scale/zeroScalePopup", false);
    } else lodash.debounce(this.zeroScaleTimer, this.delta)();
  }

  drawGraphCount = 0;
  drawLightCount = 0;

  setMass(mass: number) {
    if (!this.firstMassCaptured) {
      this.firstMassCaptured = true;
      //check if scale has been zeroed
      if (Math.abs(mass) > 2 && mass < this.$store.state.scale.minMass) {
        //if (mass < this.$store.state.scale.minMass) {
        this.$store.commit("scale/zeroScalePopup", true);
        this.$store.commit("scale/zeroScaleCurrentTime", 0);
        lodash.debounce(this.zeroScaleTimer)();
      }
    }

    this.currentMass = mass;

    let scaleMassText = document.getElementById("scaleMassText");
    if (scaleMassText) scaleMassText.innerHTML = `${mass} kg`;

    this.drawGraphCount++;
    if (this.drawGraphCount > 5) {
      this.drawGraphCount = 0;
      let scale = this.$store.state.scale;
      let hLines = [{ value: scale.minMass, colour: "grey", id: "horizontal-graph-line" }];
      if (scale.massCaptureState === "captured") hLines.push({ value: scale.capturedMass, colour: "lime", id: "min-graph-line" });
      drawChart(this.$store.getters["scale/historyMassQueue"](), hLines, scale.massQueueSize);
    }

    this.drawLightCount++;
    if (this.drawLightCount >= 10) {
      this.drawLightCount = 0;
      this.scaleActivityToggle = !this.scaleActivityToggle;
      let scaleActivityCircle = document.getElementById("scaleActivityCircle");
      if (scaleActivityCircle) (scaleActivityCircle as any).attributes.fill.nodeValue = this.scaleActivityColour;
    }
  }

  async mounted() {
    console.log("ProcessAnimal mounted");
    this.$vuetify.goTo(0);
    this.$store.commit("scale/reset");
    await this.$store.dispatch("scale/connect");
    this.$store.commit("scale/massCallback", this.setMass);

    this.$store.commit("allflex/reset");
    let processingResult: Models.ProcessingResult = this.$store.getters["processing/getField"]("currentBatchSetup.processingResult") as Models.ProcessingResult;
    if (processingResult.tagTypes.includes("allflex")) {
      this.$store.dispatch("allflex/connect");
    } else {
      console.log("allflex disabled, not connecting");
    }

    this.processTagButtonState = "ready";

    initializeChart(800);
  }

  destroyed() {
    this.$store.commit("scale/reset");
    this.$store.dispatch("scale/disconnect");
    this.$store.commit("scale/massCallback", undefined);

    this.$store.commit("allflex/reset");
    this.$store.dispatch("allflex/disconnect");
  }

  sortingGate: { gate?: Models.SortingGate; destination?: { type: string /*"ranch" | "feedlot"*/; kraalId: string } } | "" = "";

  sortingGateButtonStateError: boolean = false;
  sortingGateErrorMessage: string = "";
  determineSortingGate() {
    this.sortingGateErrorMessage = "";
    try {
      this.sortingGateButtonStateError = false;
      this.sortingGate = this._determineSortingGate() || "";
    } catch (err) {
      this.sortingGateButtonStateError = true;
      this.sortingGateErrorMessage = "Sorting gate not setup correctly. Call supervisor. " + err.message;
      this.$store.commit("popup/displayOk", this.sortingGateErrorMessage);
      this.$store.commit("log/message", { message: err.message, type: "error" });
    }
  }

  _determineSortingGate() {
    let mass = this.$store.state.scale.capturedMass;
    let gender = this.$store.state.processing.currentAnimal.gender;
    let isAnimalSick = this.isAnimalSick;

    if (mass === undefined || !gender) {
      this.$store.commit("processing/updateField", { path: "currentAnimal.sorting", value: undefined });
      this.updateVaccinations([]);

      return undefined;
    }

    let processingResult: Models.ProcessingResult = this.$store.getters["processing/getField"]("currentBatchSetup.processingResult") as Models.ProcessingResult;
    let sortGateConfig: Models.GateConfigComponent | undefined = lodash.find(processingResult.sortingConfig, function (o) {
      if (o.condition[0] && (o.condition[0].min as any) === "") o.condition[0].min = undefined;
      if (o.condition[0] && (o.condition[0].max as any) === "") o.condition[0].max = undefined;

      let min = Number.MIN_SAFE_INTEGER;
      let max = Number.MAX_SAFE_INTEGER;

      //if (o.condition[0] && o.condition[0].min === undefined && o.condition[0].max !== undefined) min = 0;
      if (o.condition[0] && o.condition[0].min !== undefined) min = o.condition[0].min;
      //if (o.condition[0] && o.condition[0].min !== undefined && o.condition[0].max === undefined) max = mass + 1;
      if (o.condition[0] && o.condition[0].max !== undefined) max = o.condition[0].max;

      if (!o.condition[0] || (o.condition[0].min === undefined && o.condition[0].max === undefined)) return false;
      return (o.gender === "any" || o.gender === gender) && o.condition[0] && o.condition[0].type === "mass" && min <= mass && mass < max;
    });

    if (!sortGateConfig) {
      this.$store.commit("processing/updateField", { path: "currentAnimal.sorting", value: undefined });
      this.updateVaccinations([]);
      throw Error("No sorting gate found");
    }

    if (sortGateConfig.destinationType === undefined) throw Error("sorting gate found but has no destination type");
    if (sortGateConfig.destinationKraalId === undefined) throw Error("sorting gate found but has no destination kraal");
    if (sortGateConfig.vaccinations.length === 0) throw Error("sorting gate found but has no vaccinations");

    let vaccinationToApply = isAnimalSick ? sortGateConfig.vaccinations[1].vaccination : sortGateConfig.vaccinations[0].vaccination;
    if (!vaccinationToApply) {
      this.updateVaccinations([]);
      throw Error("No vaccination found (_determineSortingGate). Animal sick: " + isAnimalSick);
    }
    let appliedVaccination: Models.AppliedVaccination = new Models.AppliedVaccination();

    appliedVaccination.vaccination = vaccinationToApply;

    for (let dose of vaccinationToApply.doses) {
      let appliedDose = new Models.AppliedDose();
      if (!dose.item) throw Error(`Sorting gate ${sortGateConfig.gate.id} vaccine ${vaccinationToApply.description} has a dose that has no dispensary item`);
      appliedDose.item = dose.item;
      appliedDose.unit = dose.unit;
      if (!dose.amount) {
        throw Error(`Sorting gate ${sortGateConfig.gate.id} vaccine ${vaccinationToApply.description} has a dose that does not have amount set`);
      }
      if (dose.type === "cc-mass-dependent") {
        if (!dose.kgPerDose) {
          throw Error(
            `Sorting gate ${sortGateConfig.gate.id} vaccine ${vaccinationToApply.description} has a dose that is cc-mass-dependent but does not have kgPerDose set`
          );
        }
        //TODO: Math.ceil might not work for other feedlots. So far this is good for Sernick. We might need this to be configurable
        appliedDose.actualDose = Math.ceil((mass / dose.kgPerDose) * dose.amount);
      } else {
        appliedDose.actualDose = dose.amount;
      }
      appliedVaccination.appliedDoses.push(appliedDose);
    }

    this.updateVaccinations([{ description: isAnimalSick ? "sick" : "normal", vaccination: appliedVaccination }]);

    let ret = { gate: sortGateConfig.gate, destination: { type: sortGateConfig.destinationType, kraalId: sortGateConfig.destinationKraalId } };
    this.$store.commit("processing/updateField", { path: "currentAnimal.sorting", value: ret });
    this.$store.commit("log/message", { message: `Sorting gate ${sortGateConfig.gate.description}`, type: "info" });
    this.$store.dispatch("sortingGates/openGate", ret.gate.id);
    return ret;
  }

  getDoses(): Models.Dose[] {
    let currentAnimal: Models.ProcessedAnimal = this.$store.state.processing.currentAnimal;
    return currentAnimal.vaccinations.length && currentAnimal.vaccinations[0].vaccination && currentAnimal.vaccinations[0].vaccination.vaccination
      ? currentAnimal.vaccinations[0].vaccination.vaccination.doses
      : [];
  }

  getDoseTypes(): { [doseDescription: string]: "cc-mass-dependent" | "constant" } {
    let ret: { [doseDescription: string]: "cc-mass-dependent" | "constant" } = {};
    for (let dose of this.getDoses()) {
      if (dose.item && dose.type) ret[dose.item.description] = dose.type;
      else throw Error("TODO: fix error handling for when dose not found");
    }
    return ret;
  }

  rerenderDosesKey: number = 0;
  rerenderDoses() {
    this.rerenderDosesKey += 1;
  }

  rerenderTableKey: number = 0;
  rerenderTable() {
    this.rerenderTableKey += 1;
  }

  // rerenderGateSummaryKey: number = 0;
  // rerenderGateSummary() {
  //   this.rerenderGateSummaryKey += 1;
  // }

  updateVaccinations(vaccinations: Models.DescriptionAppliedVaccination[]) {
    this.vaccinations = vaccinations;
    Vue.set(this.$store.state.processing.currentAnimal, "vaccinations", vaccinations);
    this.$store.commit("processing/updateField", { path: "currentAnimal.vaccinations", value: vaccinations });

    const doseTypes = this.getDoseTypes();

    this.appliedDoses = this.vaccinations.length && this.vaccinations[0].vaccination ? this.vaccinations[0].vaccination.appliedDoses : [];
    this.appliedDosesMassDependent = this.appliedDoses.filter((ad) => doseTypes[ad.item.description] === "cc-mass-dependent");
    this.appliedDosesMassConstant = this.appliedDoses.filter((ad) => doseTypes[ad.item.description] === "constant");

    this.rerenderDoses();
  }

  vaccinations: Models.DescriptionAppliedVaccination[] = [];

  appliedDoses: Models.AppliedDose[] = [];
  appliedDosesMassDependent: Models.AppliedDose[] = [];
  appliedDosesMassConstant: Models.AppliedDose[] = [];

  // get appliedDoses(): Models.AppliedDose[] {
  //   return this.vaccinations.length && this.vaccinations[0].vaccination ? this.vaccinations[0].vaccination.appliedDoses : [];
  // }

  // get appliedDosesMassDependent() {
  //   return this.appliedDoses.filter(ad => this.doseTypes[ad.item.description] === "cc-mass-dependent");
  // }

  // get appliedDosesMassConstant() {
  //   return this.appliedDoses.filter(ad => this.doseTypes[ad.item.description] === "constant");
  // }

  isBatchFinishedByAnimalQuantity: boolean = false;

  determineIsBatchFinishedByAnimalQuantity() {
    let batchDetails = this.$store.getters["processing/getField"]("currentBatchSetup.batchDetails");
    this.isBatchFinishedByAnimalQuantity = this.numProcessedAnimals >= batchDetails.quantity;
    if (this.isBatchFinishedByAnimalQuantity) {
      this.$store.commit("log/message", { message: "Batch finished.", type: "info" });
    }
  }

  @Watch("$store.state.scale.massCaptureState")
  massCaptureStateChange(newVal: any, oldVal: any) {
    if (newVal === "failed") this.$store.commit("log/message", { message: "Failed to capture mass.", type: "error" });
  }

  @Watch("currentMass")
  currentMassChange(newVal: any, oldVal: any) {}

  zeroScalePopup: boolean = false;

  get captureMassButtonState(): ButtonState {
    if (this.isBatchFinishedByAnimalQuantity) return "disabled";

    //if (!this.$store.state.scale.connected) return "error";

    switch (this.$store.state.scale.massCaptureState) {
      case "not-capturing":
        return "ready";
      case "capturing":
        return "busy";
      case "captured":
        return "success";
      case "failed":
        return "error";
      default:
        return "ready";
    }
  }

  visualTagChange(visualTag: any) {
    this.visualTag = visualTag;

    let currentAnimal: Models.ProcessedAnimal = this.$store.state.processing.currentAnimal;
    let i = lodash.findIndex(currentAnimal.otherTags, (o: Models.ProcessedAnimalTag) => o.type === "visual");
    if (i < 0) {
      currentAnimal.otherTags.push({ entry: "manual", type: "visual", value: this.visualTag });
    } else {
      currentAnimal.otherTags[i].value = this.visualTag;
    }
    this.$store.commit("processing/updateField", { path: "currentAnimal.otherTags", value: currentAnimal.otherTags });
  }

  visualTag: string = "";

  autoAdvanceVisualTag: boolean = false;
  previousVisualTag: string = "";

  processTagButtonState: ButtonState = "ready";

  get processingResult(): Models.ProcessingResult {
    return this.$store.getters["processing/getField"]("currentBatchSetup.processingResult");
  }

  get allowedAnimals(): Models.AllowedAnimal[] {
    return this.$store.state.processing.currentBatchSetup.batchDetails?.allowedAnimals ?? [];
  }

  waitProgramTag: boolean = false;
  waitProgramTagTimeout = 0;

  async programTag() {
    if (this.processTagButtonState === "busy") return;
    if (this.waitProgramTag) return;
    this.waitProgramTag = true;
    this.confirmTagButtonState = "ready";
    this.tagConfirmed = false;
    try {
      this.processTagButtonState = "busy";
      this.$store.commit("log/message", { message: "Programming tag...", type: "info" });
      let sgtin = await this.$store.dispatch("programTag", { busyProcessing: true });
      this.$store.commit("processing/updateField", { path: "currentAnimal.sgtin", value: sgtin });
      this.processTagButtonState = "success";
      this.$store.commit("log/message", { message: `Tag successfully programmed.\n(${sgtin})`, type: "info" });
      const self = this;
      setTimeout(function () {
        self.waitProgramTag = false;
      }, this.waitProgramTagTimeout);
    } catch (err) {
      this.processTagButtonState = "error";
      this.$store.commit("processing/updateField", { path: "currentAnimal.sgtin", value: "" });
      this.$store.commit("log/message", { message: "Error programming tag: " + (err.message ? err.message : err), type: "error" });
      const self = this;
      setTimeout(function () {
        self.waitProgramTag = false;
      }, this.waitProgramTagTimeout);
      throw err;
    }
  }

  confirmTagButtonState: ButtonState = "ready";
  tagConfirmed: boolean = false;

  retagAllowed: boolean = false;

  hasRetag() {
    return this.$store.state.processing.currentAnimal?.retag;
  }

  async confirmTag() {
    if (this.processTagButtonState === "busy") return;
    try {
      this.retagAllowed = false;
      this.$store.commit("log/message", { message: "Confirming tag...", type: "info" });
      this.confirmTagButtonState = "busy";
      let sgtin: string = "";
      try {
        sgtin = await this.$store.dispatch("readTag"); //readTag does error checking and throws exceptions
      } catch (err) {
        //Only allow retag if reader could not pick up a tag.
        if (err && err.message && err.message.toLowerCase().includes("no tag found")) {
          this.retagAllowed = true;
        }
        throw err;
      }
      let processingResult: Models.ProcessingResult = this.$store.getters["processing/getField"](
        "currentBatchSetup.processingResult"
      ) as Models.ProcessingResult;
      let processedAnimals: Models.ProcessedAnimal[] = await this.$store.getters["processing/currentProcessedAnimals"]();
      if (!sgtin) throw Error("Empty tag returned.");
      //if processing function is "new" then confirm tag checks if sgtin is the same as programmed sgtin
      //TODO: check visual sgtin rather?
      this.$store.commit("log/message", { message: "Confirming tag sgtin: " + sgtin, type: "info" });
      if (processedAnimals.find((pa) => pa.sgtin && sgtinH.visualGcpMatch(pa.sgtin, sgtin))) throw Error(`Tag already in batch (${sgtin})`);
      //if "new" processing then tag must be confirmed against tag that was programmed
      if (processingResult.processingFunction === "new" && !this.hasRetag()) {
        //If no incoming animals then check against programmed tag
        if (this.allowedAnimals.length === 0) {
          if (sgtin !== this.$store.state.processing.currentAnimal.sgtin)
            throw Error(`Tag mismatch. Expected ${this.$store.state.processing.currentAnimal.sgtin} but got ${sgtin}.`);
          this.tagConfirmed = !!sgtin && sgtin === this.$store.state.processing.currentAnimal.sgtin;
        } else {
          //If we're here then we have a new intake batch with incoming animals
          await checkTagAgainstAnimalsInBatch(sgtin);
          await checkTagAgainstAnimalsInDatabase(sgtin);
          //Check if tag is in list of allowedAnimals
          const animal = this.allowedAnimals.find((a) => a.sgtin === sgtin);
          if (!animal) throw Error(`Tag not in incoming list: ${sgtinH.visual(sgtin)}\n${sgtin}`);
          //If we're here then tag is not already in system and is in allowedAnimals
          this.$store.commit("processing/updateField", { path: "currentAnimal.sgtin", value: sgtin });
          this.breed = animal.breed ?? "";
          this.$store.commit("processing/updateField", { path: "currentAnimal.breed", value: animal.breed });
          this.gender = animal.gender ?? "";
          this.$store.commit("processing/updateField", { path: "currentAnimal.gender", value: animal.gender });
          this.tagConfirmed = true;
          this.determineSortingGate();
        }
      }
      //otherwise we must consider if the animal has been retagged in this process.
      //if it has then we confirm the tag against what was just programmed with the retagging.
      else if (this.hasRetag()) {
        if (!this.$store.state.processing.currentAnimal.sgtin) {
          throw Error(`Animal's retag tag has not yet been programmed.`);
        } else if (this.$store.state.processing.currentAnimal.sgtin === sgtin) {
          this.tagConfirmed = true;
        } else {
          throw Error(`Animal has been retagged with ${this.$store.state.processing.currentAnimal.sgtin} but got ${sgtin} instead.`);
        }
      }
      //if we are here then animal is not in "new" process and has not been retagged
      //in this case we can just lookup the animal and make do our checks to see if animal is recognized, if owner is correct, etc...
      else if (!this.hasRetag()) {
        this.$store.commit("processing/updateField", { path: "currentAnimal.sgtin", value: sgtin });
        try {
          console.log(sgtin);
          let animal = await this.$store.dispatch("dataManager/getAnimal", sgtin);
          if (!animal) throw Error(`Animal not recognized: ${sgtin}`);
          if (animal.breed) {
            this.breed = animal.breed;
            this.$store.commit("processing/updateField", { path: "currentAnimal.breed", value: animal.breed });
          }
          if (animal.gender) {
            this.gender = animal.gender;
            this.$store.commit("processing/updateField", { path: "currentAnimal.gender", value: animal.gender });
          }
          this.determineSortingGate();
          if (animal.customFeeder) {
            let customFeeder = this.$store.state.processing.currentBatchSetup.batchDetails.customFeeder;
            // console.log("animal.customFeeder.guid: " + animal.customFeeder.guid);
            // console.log("customFeeder.guid: " + customFeeder.guid);
            if (animal.customFeeder.guid !== customFeeder.guid) {
              console.log("animal.customFeeder.guid: " + animal.customFeeder.guid);
              console.log("customFeeder.guid: " + customFeeder.guid);
              let customFeederToString = (customFeeder: any) => `${customFeeder.name} ${customFeeder.surname}`;
              let expectedOwner = customFeederToString(customFeeder);
              let animalOwner = customFeederToString(animal.customFeeder);
              let errorMessage = `Owner Mismatch!\nSupervisor set owner to "${expectedOwner}", but current animal's owner is "${animalOwner}"\n${sgtin}`;
              this.$store.commit("popup/displayOk", errorMessage);
              throw Error(errorMessage);
            }
          } else throw Error("No owner found for animal " + sgtin);
          //If no errors were thrown and we made it this far then we can consider the tag as confirmed.
          this.tagConfirmed = true;
        } catch (err) {
          console.log("error querying animal: " + err);
          throw err;
        }
      }

      this.confirmTagButtonState = this.tagConfirmed ? "success" : "error";
      if (this.tagConfirmed) {
        this.$store.commit("log/message", { message: `Tag successfully confirmed.\n(${sgtin})`, type: "info" });
        this.retagAllowed = false;
      } else this.$store.commit("log/message", { message: "Error confirming tag.", type: "error" });

      this.updateAdgUi(sgtin, this.$store.state.scale.capturedMass ?? "");
    } catch (err) {
      //if animal doesn't have retag then wipe sgtin, otherwise if animal has been retagged then keep sgtin
      //if animal is not in new processing then wipe tag (as this would then just count as a failed tag read, tag confirm in other processing functions are just tag reads)
      if (!this.hasRetag() && (this.processingResult.processingFunction !== "new" || this.allowedAnimals.length > 0))
        this.$store.commit("processing/updateField", { path: "currentAnimal.sgtin", value: "" });
      this.tagConfirmed = false;
      this.confirmTagButtonState = "error";
      this.$store.commit("log/message", { message: "Error confirming tag: " + (err.message ? err.message : err), type: "error" });
    }
  }

  async onClickCaptureMass() {
    try {
      this.$store.commit("log/message", { message: "Capturing mass...", type: "info" });
      await this.$store.dispatch("scale/captureMass");
    } catch (err) {
      //this.uiMessage = "Error: " + err.message;
      this.$store.commit("log/message", { message: "Error capturing mass: " + (err.message ? err.message : err), type: "error" });
    }
  }

  async addNewAnimalToLocalDatabase(animal: Models.ProcessedAnimal, customFeeder: Models.CustomFeeder) {
    await this.$store.dispatch("dataManager/saveData", {
      objectStore: "Animal",
      data: {
        sgtin: animal.sgtin,
        visualSgtin: sgtinH.visual(animal.sgtin || ""),
        gender: animal.gender,
        breed: animal.breed,
        customFeeder: customFeeder,
        events: [{ time: animal.time, mass: animal.mass, type: "processing_new" }],
      },
    });
  }

  finishButtonState: ButtonState = "ready";

  get finishAnimalButtonState(): ButtonState {
    if (!this.sortingGate || !this.tagConfirmed) return "disabled";
    if (this.processingResult.countTeeth && this.numTeeth === "") return "disabled";
    if (this.finishAnimalError) return "error";
    return "ready";
  }
  finishAnimalError: boolean = false;

  async onClickFinishAnimal() {
    if (!this.tagConfirmed) {
      this.$store.commit("log/message", { message: "Tag must first be confirmed", type: "error" });
      this.confirmTagButtonState = "error";
      return;
    }
    try {
      await this.finishAnimal();
      this.finishAnimalError = false;
      this.processTagButtonState = "ready";
      this.confirmTagButtonState = "ready";
    } catch (err) {
      this.$store.commit("log/message", { message: "Error: " + (err.message ? err.message : err), type: "error" });
      this.finishAnimalError = true;
    }
  }

  /*
    When saving an animal we might need to upload the batch setup.
    We can load the batch setup and check if it's been uploaded.
    Instead of doing this every time we can use batchCheckedAndUploaded.
    If batchCheckedAndUploaded is true then don't load the batch setup,
    we have already checked.
  */

  batchCheckedAndUploaded: boolean = false;

  //finish animal
  async finishAnimal() {
    let currentAnimal: Models.ProcessedAnimal = this.$store.state.processing.currentAnimal;
    let processingResult: Models.ProcessingResult = this.$store.getters["processing/getField"]("currentBatchSetup.processingResult") as Models.ProcessingResult;

    if (!currentAnimal.sgtin) {
      throw Error("sgtin required");
    }

    if (this.$store.state.scale.capturedMass === undefined) {
      throw Error("mass required");
    }

    if (!currentAnimal.gender) {
      throw Error("gender required");
    }

    if (!currentAnimal.breed) {
      throw Error("breed required");
    }

    if (!currentAnimal.sorting) {
      throw Error("sorting gate required");
    }

    if (!currentAnimal.vaccinations.length && !currentAnimal.vaccinations[0].vaccination) {
      throw Error("vaccination required");
    }

    if (!this.tagConfirmed) {
      throw Error("Tag has not been confirmed.");
    }

    // Check if batch setup has been uploaded.
    if (!this.batchCheckedAndUploaded) {
      let savedBatchSetup = await this.$store.dispatch("dataManager/getBatchSetup", this.$store.state.processing.currentBatchSetup.guid);
      //console.log("savedBatchSetup: ", JSON.stringify(savedBatchSetup));
      //console.log("savedBatchSetup.uploaded: ", savedBatchSetup?.uploaded);
      if (!savedBatchSetup || !savedBatchSetup.uploaded) {
        try {
          console.log("saving and uploading");
          /*
            The batch setup can be marked as finished, the batch setup used to be sent when the batch was 
            completed but now it's sent once the first animal has been processed. For logic to work nicely
            on the office server it will be easier to just mark batch setup as finished.
            
            The user can't use this batch setup again* in any case. So think of finished as meaning 
            the batch setup is finished from being selectable from saved batch setups.
            
            * they could make another batch setup with the same batch details
          */
          this.$store.commit("processing/updateField", { path: "currentBatchSetup.finished", value: true });
          await this.$store.dispatch("dataManager/saveData", { data: this.$store.state.processing.currentBatchSetup, objectStore: "BatchSetup" });
          this.$store.commit("numUnUploadedItems", "increment");
          this.$store.dispatch("upload/uploadBatchSetups", undefined, { root: true });
        } catch (err) {
          console.log("error saving batch setup to database");
          throw err;
        }
      }
      this.batchCheckedAndUploaded = true;
    }

    currentAnimal.mass = this.$store.state.scale.capturedMass;

    if (currentAnimal.vaccinations.length && currentAnimal.vaccinations[0].vaccination) {
      currentAnimal.vaccinations[0].vaccination.sgtin = currentAnimal.sgtin;
      currentAnimal.vaccinations[0].vaccination.time = Date.now();
    }

    if (this.$store.state.allflex.allflex && processingResult.tagTypes.includes("allflex")) {
      let i = lodash.findIndex(currentAnimal.otherTags, function (o) {
        return o.type === "allflex";
      });
      if (i < 0) {
        currentAnimal.otherTags.push({ entry: "auto", type: "allflex", value: this.$store.state.allflex.allflex });
      } else {
        currentAnimal.otherTags[i].value = this.$store.state.allflex.allflex;
        currentAnimal.otherTags[i].entry = "auto";
      }
    }

    currentAnimal.isNonStandard = this.isNonStandard;

    currentAnimal.time = Date.now();

    //this.processingResult.processedAnimals.push(currentAnimal);
    await this.$store.dispatch("processing/addProcessedAnimal", { processedAnimal: currentAnimal, processingResultGuid: this.processingResult.guid });

    if (this.$store.state.processing.currentBatchSetup.processingResult.processingFunction === "new") {
      let customFeeder = this.$store.state.processing.currentBatchSetup.batchDetails.customFeeder;
      this.addNewAnimalToLocalDatabase(currentAnimal, customFeeder);
    }

    //store animal in IndexedDB (with guid of ProcessingResult)
    await this.$store.dispatch("dataManager/saveData", {
      data: { ...currentAnimal, processingResultGuid: processingResult.guid },
      objectStore: "ProcessedAnimal",
    });
    this.$store.commit("numUnUploadedItems", "increment");

    await this.$store.dispatch("dataManager/saveData", { data: { guid: currentAnimal.guid }, objectStore: "UnUploadedProcessedAnimalGuids" }, { root: true });

    this.$store.dispatch("upload/uploadProcessedAnimals", undefined, { root: true });

    // TODO: If first animal in batch then upload currentBatchSetup as well.

    //Update retagged animal's sgtin locally
    if (currentAnimal.retag?.sgtin) {
      let animal: Models.Animal & { visualSgtin: string } = await this.$store.dispatch("dataManager/getAnimal", currentAnimal.retag.sgtin);
      //Animal data stored in array, so updating fields will update by reference.
      // (if in animal queue this will work, but also save to db in case animal already in db)
      if (animal) {
        animal.sgtin = currentAnimal.sgtin;
        animal.visualSgtin = sgtinH.visual(currentAnimal.sgtin || "");
      }
      // Update animal in db, if animal queue then animal will just be updated with same data.
      await this.$store.dispatch("dataManager/saveData", {
        objectStore: "Animal",
        data: animal,
      });
    }

    const ncf: Models.CustomFeeder = this.$store.state.processing.currentBatchSetup.batchDetails.newCustomFeeder;
    if (ncf) {
      let animal: Models.Animal = await this.$store.dispatch("dataManager/getAnimal", currentAnimal.sgtin);
      //Animal data stored in array, so updating fields will update by reference.
      // (if in animal queue this will work, but also save to db in case animal already in db)
      if (animal) {
        animal.customFeeder = ncf;
        animal.owner = ncf?.name;
      }
      // Update animal in db, if animal queue then animal will just be updated with same data.
      await this.$store.dispatch("dataManager/saveData", {
        objectStore: "Animal",
        data: animal,
      });
    }

    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult", value: processingResult });
    // currentAnimal = new Models.ProcessedAnimal();
    // currentAnimal.sgtin = "";
    //currentAnimal.vaccinations = new Models.AppliedVaccination();
    let pa = new Models.ProcessedAnimal();
    pa.notes = "";
    pa.isNonStandard = false;
    this.isNonStandard = false;

    this.$store.commit("processing/updateField", { path: "currentAnimal", value: pa });
    this.updateVaccinations([]);

    this.$store.commit("scale/reset");
    //this.$store.commit("allflex/reset"); //TODO: this makes the lf rfid status show a red cross
    this.$store.commit("allflex/allflex", "");
    this.$store.commit("processing/updateField", { path: "isAnimalSick", value: false });
    this.genderChange("");
    this.breedChange("");
    this.numTeethChange("");
    // if (this.$store.getters["processing/getField"]("currentBatchSetup.processingFunction") !== "new") {
    //   this.$store.commit("processing/updateField", { path: "currentBatchSetup.batchDetails.customFeeder", value: { name: "", surname: "" } });
    // }
    if (this.autoAdvanceVisualTag) {
      this.previousVisualTag = this.visualTag;
      const m = this.previousVisualTag.match(/(.*?)([0-9]+)$/); // ? by .* to make it non-greedy
      // if matched then m[2] will contain number, m[1] whatever before, m[0] the whole string
      if (m?.length === 3) {
        console.log(JSON.stringify(m));
        let originalNumberLength = m[2].length;
        let n = parseInt(m[2]) + 1;
        this.visualTag = m[1] + `${n}`.padStart(originalNumberLength, "0");
        this.visualTagChange(this.visualTag);
      } else {
        this.visualTag = "";
      }
    } else {
      this.previousVisualTag = "";
      this.visualTag = "";
    }
    this.isAnimalSick = false;
    this.tagConfirmed = false;
    this.$forceUpdate();
    this.rerenderTable();
    this.rerenderDoses();
    //this.rerenderGateSummary();
    this.calcProcessedAnimals();
    this.calcGatesSummary();
    await this.calcAdgs();
    this.determineIsBatchFinishedByAnimalQuantity();

    //if batch is new intake and not finished then check to autofill gender and breed
    this.checkAutofillGenderBreed();

    this.updateAdgUi(undefined, "");
  }

  checkAutofillGenderBreed() {
    let processingResult: Models.ProcessingResult = this.$store.getters["processing/getField"]("currentBatchSetup.processingResult") as Models.ProcessingResult;
    if (!this.isBatchFinishedByAnimalQuantity && processingResult.processingFunction === "new" && this.allowedAnimals.length === 0) {
      if (processingResult.genders.length === 1) {
        this.genderChange(processingResult.genders[0]);
      }
      if (processingResult.breeds.length === 1) {
        this.breedChange(processingResult.breeds[0]);
      }
    }
  }

  async finish() {
    console.log("finish");

    const t = await this.$store.getters["processing/currentProcessedAnimals"]();
    if (t.length === 0) {
      console.log("finish() with no animals processed, not saving this batch setup");
      this.$store.commit("processing/updateField", { path: "busyProcessing", value: false });
      this.$router.push({ name: "home" });
      return;
    }
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: async () => {
        this.$store.commit("popup/hide");
        try {
          this.finishButtonState = "busy";

          await this.$store.dispatch("processing/clearCurrentProcessedAnimals");

          //this.$store.commit("processing/updateField", { path: "currentBatchSetup.finished", value: true });
          //Do we need to save this again? I think it's saved as unuploaded (uploaded: false).
          //await this.$store.dispatch("dataManager/saveData", { data: this.$store.state.processing.currentBatchSetup, objectStore: "BatchSetup" });
          //TODO: upload batch setup again? No maybe not.

          this.$store.commit("processing/updateField", { path: "currentAnimal", value: undefined });
          this.$store.commit("processing/updateField", { path: "currentBatchSetup", value: undefined });
          this.$store.commit("processing/updateField", { path: "quantity", value: undefined });
          this.$store.commit("processing/updateField", { path: "busyProcessing", value: false });
          this.$store.commit("processing/updateField", { path: "isAnimalSick", value: false });

          this.finishButtonState = "success";
          this.$router.push({ name: "home" });
        } catch (err) {
          this.finishButtonState = "error";
          //console.log("finish() error: ", err);
          this.$store.commit("log/message", { message: "Error: " + err.message, type: "error" });
          throw err;
        }
      },
    });
  }

  @Watch("$store.state.scale.capturedMass")
  onMassChanged(val: any, oldVal: any) {
    //console.log("mass change: " + val);
    if (!isNaN(val)) {
      this.$store.commit("log/message", { message: "Mass captured successfully.", type: "info" });

      let pa: Models.ProcessedAnimal = this.$store.getters["processing/getField"]("currentAnimal");
      this.updateAdgUi(pa.sgtin, val);
    }

    this.determineSortingGate();
  }
}
