import { default as Vuex, Module, ActionContext } from "vuex";
import * as Models from "@gigalot/data-models";
import { ProcessedAnimals } from "@/helpers/processed-animals";

export let processedAnimals: ProcessedAnimals = new ProcessedAnimals();

const vuexMapFields = require("vuex-map-fields");

export const { mapFields: mapProcessingFields } = vuexMapFields.createHelpers({
  getterType: "processing/getField",
  mutationType: "processing/updateField"
});

export class ProcessingState {
  currentBatchSetup?: Models.BatchSetup;
  //processingResult: any;
  //currentAnimal: any;
  //batchDetailsList: any;
  //selectedBatchDetails: any;
  quantity: any;
  busyProcessing: boolean = false;
  numProcessedAnimals: number = 0;
  /*
    savedSettings
      if any field is not undefined then that field's settings can be used to restore setting
  */
  savedSettings: {
    //tagTypes?: string[],
    allflexTag?: boolean,
    visualTag?: boolean,
    checkNonStandard?: boolean,
    countTeeth?: boolean;
    /*breeds?: string[], genders?: string[], */
  } = {};
}

class Processing implements Module<ProcessingState, any> {
  namespaced = true;
  state: ProcessingState = new ProcessingState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    updateField: vuexMapFields.updateField
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async onAppCreated(context: ActionContext<ProcessingState, any>) {
      const guid = context.state.currentBatchSetup?.processingResult?.guid;
      if (guid) {
        await processedAnimals.restore(guid, context);
        context.commit("updateField", { path: "numProcessedAnimals", value: (await processedAnimals.getCurrentAnimals()).length });
      }
    },
    async addProcessedAnimal(context: ActionContext<ProcessingState, any>, o: { processedAnimal: Models.ProcessedAnimal; processingResultGuid: string; }) {
      await processedAnimals.add(o.processedAnimal, o.processingResultGuid, context);
      context.commit("updateField", { path: "numProcessedAnimals", value: (await processedAnimals.getCurrentAnimals()).length });
      //upload
    },
    async getProcessedAnimals(context: ActionContext<ProcessingState, any>, o: { processingResultGuid: string; }) {
      return await processedAnimals.get(o.processingResultGuid, context);
    },
    async clearCurrentProcessedAnimals(context: ActionContext<ProcessingState, any>) {
      processedAnimals.clear();
      context.commit("updateField", { path: "numProcessedAnimals", value: 0 });
    }
  };
  getters = {
    /*
    getter(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    currentProcessedAnimals(state: ProcessingState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return processedAnimals.getCurrentAnimals();
      };
    },
    // processedAnimals(state: ProcessingState, getters: any, rootState: any, rootGetters: any) {
    //   return async (processingResultGuid?: string) => {
    //     return processedAnimals.get(processingResultGuid);
    //   };
    // },
    getField: vuexMapFields.getField
  };
}

export default new Processing()
